import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { NewBtn } from '../../components/styleComponents';
import getData from '../../utils/getData';

import Loader from '../../components/Loader';
import ModalParametro from '../../components/Parametro/ModalParametro';
import Parametro from '../../components/Parametro/Parametro';
import { IParametro } from '../../interfaces';

const Parametros: React.FC = () => {
    const [showContent, setShowContent] = useState(false);
    const [params, setParams] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        getData<IParametro[]>(
            '/parametros',
            (data: IParametro[]) => {
                const trimedData = data.map((parametro) => ({
                    ...parametro,
                    parConsultaSetor: parametro.parConsultaSetor.trim(),
                    parConsultaFG: parametro.parConsultaFG.trim(),
                    parConsultaFiscal: parametro.parConsultaFiscal.trim(),
                    parConsultaLotacao: parametro.parConsultaLotacao.trim(),
                    parConsultaUsuario: parametro.parConsultaUsuario.trim(),
                    parConsultaColaborador: parametro.parConsultaColaborador.trim(),
                    parConsultaAniversariante: parametro.parConsultaAniversariante.trim()
                }));
                setParams(trimedData);
            },
            history
        ).then(() => setShowContent(true));
    }, [history]);

    if (showContent) {
        return (
            <div>
                <NewBtn type="button" onClick={() => setModalIsOpen(true)}>
                    Novo Parâmetro
                </NewBtn>
                {params.map((param) => (
                    <Parametro key={param.parId} param={param} state={{ params, setParams }} />
                ))}
                <ModalParametro
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    state={{ params, setParams }}
                />
            </div>
        );
    }
    return <Loader />;
};

export default Parametros;
