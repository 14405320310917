import React from 'react';
import { VscSymbolParameter, VscReport, VscKey, VscSignOut } from 'react-icons/vsc';
import { Link, useHistory } from 'react-router-dom';
import { BiShareAlt } from 'react-icons/bi';
import { ChildrenContainer, NavItems, NavTop, Sidebar } from './style';
import cmcImg from '../../assets/brasao.png';

const Layout: React.FC = ({ children }) => {
    const history = useHistory();

    const logout = (): void => {
        sessionStorage.removeItem('token');
        history.push('/');
    };

    return (
        <>
            <Sidebar>
                <NavTop>
                    <img src={cmcImg} alt="brasão cmc" />
                    <h2>CMC-API</h2>
                    <button onClick={logout} type="button">
                        <VscSignOut size={40} />
                        <p>Logout</p>
                    </button>
                </NavTop>

                <NavItems>
                    <li>
                        <Link to="/parametros">
                            <VscSymbolParameter size={40} />
                            <span>Parâmetros</span>
                        </Link>
                    </li>

                    <li>
                        <Link to="/chaves">
                            <VscKey size={30} />
                            <span>Chaves</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/relatorios">
                            <VscReport size={30} />
                            <span>Relatórios</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/areas">
                            <BiShareAlt size={30} />
                            <span>Áreas</span>
                        </Link>
                    </li>
                </NavItems>
            </Sidebar>
            <ChildrenContainer>{children}</ChildrenContainer>
        </>
    );
};

export default Layout;
