import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Loader from '../../components/Loader';
import { Container } from '../../components/styleComponents';
import Vazio from '../../components/Vazio/Vazio';
import getData from '../../utils/getData';
import Grupo from '../../components/Grupo/Grupo';
import { IGrupo } from '../../interfaces';

const Relatorios: React.FC = () => {
    const [showContent, setShowContent] = useState(false);
    const [grupos, setGrupos] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getData<IGrupo[]>('/relatorios', setGrupos, history).then(() => setShowContent(true));
    }, [history]);

    const apagaGrupo = (idGrupo: number): void => {
        const novos = grupos.filter(({ grpId }) => grpId !== idGrupo);
        setGrupos(novos);
    };

    if (showContent) {
        return grupos.length === 0 ? (
            <Vazio text="relatórios" />
        ) : (
            <div>
                {grupos.map((relatorio: IGrupo) => {
                    const { grpAlvo, grpMensagem, grpIdentificadores, grpAtualizado, relatorios } =
                        relatorio;
                    return (
                        <Container key={relatorio.grpId}>
                            <Grupo
                                grpId={relatorio.grpId}
                                grpAlvo={grpAlvo}
                                grpMensagem={grpMensagem}
                                grpIdentificadores={grpIdentificadores}
                                grpAtualizado={grpAtualizado}
                                relatorios={relatorios}
                                apagaHandler={apagaGrupo}
                            />
                        </Container>
                    );
                })}
            </div>
        );
    }
    return <Loader />;
};

export default Relatorios;
