import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { IParametro } from '../../interfaces';
import { Container } from '../styleComponents';
import { atualizarParametro, excluirParametro } from './handlers';
import ParamForm from '../ParamForm/ParamForm';
import formatDate from '../../utils/formatDate';

interface Props {
    param: IParametro;
    state: {
        params: IParametro[];
        setParams: React.Dispatch<React.SetStateAction<IParametro[]>>;
    };
}

const Parametro: React.FC<Props> = ({ param, state }) => {
    const [paramValues, setParamValues] = useState({
        ...param,
    });

    const primBtn = <button type="submit">Atualizar</button>;

    const scdBtn = (
        <button
            type="button"
            onClick={() => {
                confirmAlert({
                    title: 'Confirmar Exclusão',
                    message: 'Tem certeza que deseja fazer isso?',
                    buttons: [
                        {
                            label: 'Sim',
                            onClick: () => excluirParametro(param.parId, state),
                        },
                        {
                            label: 'Não',
                            onClick: () => undefined,
                        },
                    ],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }}>
            Excluir
        </button>
    );

    return (
        <Container>
            <ParamForm
                paramState={{ paramValues, setParamValues }}
                primBtn={primBtn}
                scdBtn={scdBtn}
                flexWidth="50%"
                callback={(e) => {
                    e.preventDefault();
                    atualizarParametro(paramValues);
                }}
            />
            <p>
                <strong>Criado: </strong>
                {formatDate(paramValues.parCriado)};
            </p>
            <p>
                <strong>Atualizado: </strong>
                {formatDate(paramValues.parAtualizado)};
            </p>
        </Container>
    );
};

export default Parametro;
