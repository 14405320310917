import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { FaTrash } from 'react-icons/fa';
import { IRelatorio } from '../../interfaces';
import formatDate from '../../utils/formatDate';
import Group from './style';
import { excluirGrupo } from './handlers';

interface IProps {
    grpId: number;
    grpAlvo: string;
    grpMensagem: string;
    grpIdentificadores: string;
    grpAtualizado: Date;
    relatorios: IRelatorio[];
    // eslint-disable-next-line
  apagaHandler: (idGrupo: number) => void;
}

const scdBtn = (id: number, apagaHandler: (idGrupo: number) => void): React.ReactNode => (
    <button
        type="button"
        onClick={() => {
            confirmAlert({
                title: 'Confirmar Exclusão',
                message: 'Tem certeza que deseja fazer isso?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => excluirGrupo(id).then(() => apagaHandler(id)), // excluirChave(chave.chaId, state),
                    },
                    {
                        label: 'Não',
                        onClick: () => undefined,
                    },
                ],
                closeOnEscape: true,
                closeOnClickOutside: true,
            });
        }}>
        <FaTrash size={25} />
    </button>
);

const Grupo: React.FC<IProps> = ({
    grpId,
    grpAlvo,
    grpMensagem,
    grpIdentificadores,
    grpAtualizado,
    relatorios,
    apagaHandler,
}) => {
    return (
        <Group>
            <p>
                <h1>{grpAlvo}</h1>
                <h2>{formatDate(grpAtualizado, true)}</h2>{' '}
            </p>
            <p>
                <div className="left">{grpMensagem}</div>
                <div className="right">{scdBtn(grpId, apagaHandler)}</div>
            </p>

            <table width="100%">
                <tbody>
                    <tr>
                        {grpIdentificadores.split(' ').map((id) => (
                            <th>{id}</th>
                        ))}
                    </tr>
                    {relatorios.map((item) => {
                        const ids = item.relIdentificadores.split(';');
                        return (
                            <tr key={item.relId}>
                                {ids.map((id) => (
                                    <td>{id}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Group>
    );
};

export default Grupo;
