import styled from 'styled-components';

export const Container = styled.div`
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    text-align: center;
    width: 80%;
    min-height: 10vh;
    background: #cacaca;
    border: 2px solid #000;
    margin: 2rem auto;
    &:not(:has(hr)) {
        padding-bottom: 15px;
    }
`;

export const NewBtn = styled.button`
    font-size: 25px;
    padding: 15px;
    margin: 3rem auto 0.5rem auto;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    background: #4ab73f;
`;
