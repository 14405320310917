const formatDate = (input: Date, hour = false): string => {
    const newDate = new Date(input);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate() + 1;

    const formatedDate = `${day}/${month}/${year}`;

    return !hour ? formatedDate : `${newDate.toLocaleString()}`;
};

export default formatDate;
