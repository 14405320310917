import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import NotFoundImg from '../../assets/404-error.png';

const Main = styled.main`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    img {
        width: 200px;
        opacity: 0.55;
    }

    h1 {
        font-size: 45px;
        display: flex;
        align-items: center;
        &::before,
        &::after {
            content: '';
            width: 100px;
            height: 3px;
            margin: auto 20px;
            border-radius: 8px;
            background: #7e7777;
        }
    }
`;

const NotFound: React.FC = () => {
    const history = useHistory();

    setTimeout(() => {
        history.push('/');
    }, 3000);

    return (
        <Main>
            <img src={NotFoundImg} alt="Imagem com uma tela apresentando erro 404" />
            <h1>Página Não Encontrada</h1>
            <h2>Redirecionando...</h2>
        </Main>
    );
};

export default NotFound;
