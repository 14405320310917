import styled from 'styled-components';

interface Props {
    flexWidth: string;
}

export const Form = styled.form<Props>`
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    section {
        flex: 1 0 ${(props) => props.flexWidth};
    }
    div {
        margin: 20px 50px;
        * {
            display: block;
            margin: auto;
        }

        label {
            font-size: 25px;
            font-weight: 500;
            text-align: center;
        }

        input {
            text-align: center;
            max-width: 250px;
        }

        textarea {
            width: 500px;
            height: 82px;
        }

        input,
        textarea {
            background: rgba(89, 74, 102, 0.7);
            padding: 5px;
            border: none;
            border-radius: 5px;
            box-shadow: 0px 3px 7px rgba(14, 31, 53, 0.12);
            font-size: 20px;
            color: rgba(0, 0, 0, 0.85);

            &:focus,
            &:active {
                outline: 1.5px solid #000;
            }
        }
    }

    .buttons {
        text-align: center;
        margin-bottom: 20px;
        button {
            width: 100px;
            height: 50px;
            max-width: 150px;
            max-height: 110px;
            font-size: 20px;
            &:nth-child(2) {
                background: #e02020;
            }
        }
    }
`;
