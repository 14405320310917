function handleInputs<T>(
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    values: T,
    setValues: React.Dispatch<React.SetStateAction<T>>
): void {
    const newValues = {
        ...values,
        [e.target.name]: e.target.value,
    };

    setValues(newValues);
}

export default handleInputs;
