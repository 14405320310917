import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';

const showError = (erro: AxiosError): void => {
    if (erro.response) {
        if (erro.response.status === StatusCodes.NOT_FOUND) {
            toast.error('Api inecessível');
        } else {
            toast.error(erro.response.data.error);
        }
    } else {
        toast.error(erro.message);
    }
};

export default showError;
