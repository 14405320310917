import { toast } from 'react-toastify';
import { History } from 'history';
import api from '../service/api';

async function getData<T>(
    url: string,
    // eslint-disable-next-line no-unused-vars
    dataSetter: React.Dispatch<React.SetStateAction<T>> | ((data: T) => void),
    history: History
): Promise<void> {
    const token = sessionStorage.getItem('token');
    if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        api.defaults.headers.chaValor = process.env.REACT_APP_CHAVE_API;
        try {
            const response = await api.get(url);
            const { data } = response;
            dataSetter(data);
        } catch (err: any) {
            console.log(err.response.data.error);
            if (err.response.data.error) {
                toast.error(err.response.data.error);
                history.push('/');
            }
        }
    } else {
        toast.error('Sem Token');
        history.push('/');
    }
}

export default getData;
