import { toast } from 'react-toastify';
import api from '../../service/api';
import showError from '../../utils/showError';

export const excluirGrupo = async (grpId: number): Promise<void> => {
    api.delete(`/relatorios/delete/${grpId}`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
    })
        .then(() => {
            toast.success('Chave excluída com sucesso');
        })
        .catch((erro) => {
            showError(erro);
        });
};
