import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }
    *:focus {
        outline: 0;
    }
    html, body, #root {
        min-height: 100%;
        height: 100%;
    }
    #root {
        align-items: center;
    }
    body {
        -webkit-font-smoothing: antialiased !important;
        background-size: 100%;
        background-repeat: no-repeat;
        background: rgba(0, 0, 0, 0.8);
    }
    body, input, button, textarea, pre, select, span {
        font-family: Roboto, Arial, Helvetica, sans-serif;
    }
    button {
        font-family: Roboto, Arial, Helvetica, sans-serif;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        color: #fff;
        background: #303f9f;
        padding: 7px;
        text-align: center;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
            filter: brightness(150%);
        }
    }
    input, textarea, select {
        font-size: 16px;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    ul {
        list-style: none;
    }
    .react-confirm-alert-overlay {
        background: rgba(0, 0, 0, 0.4) ;
    }
`;
