import styled from 'styled-components';
import cmcImg from '../../assets/brasao.png';

export const CenterContainer = styled.div`
    margin: auto;
    width: 100%;
    height: 100%;

    text-align: center;

    padding-top: 25vh;

    position: relative;

    &::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 80%;
        z-index: -1;
        opacity: 0.2;
        background-image: url(${cmcImg});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30%;
    }

    h1 {
        color: #fff;
        font-size: 40px;
    }
`;

export const Form = styled.form`
    margin-top: 50px;

    label,
    input {
        text-align: center;
        margin: auto;
        display: block;
        font-size: 30px;
    }

    label {
        color: #fff;
        font-size: 35px;
    }

    div {
        margin: 5px 0 5px 0;
    }

    button {
        margin: 0;
        margin-top: 10px;
        font-size: 25px;
    }
`;
