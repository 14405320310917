import React, { ReactElement } from 'react';
import { IParametro } from '../../interfaces';
import { Form } from './style';

interface Props {
    paramState: {
        paramValues: IParametro;
        setParamValues: React.Dispatch<React.SetStateAction<IParametro>>;
    };
    primBtn: ReactElement;
    scdBtn: ReactElement;
    flexWidth: string;
    // eslint-disable-next-line
    callback: (...args: any[]) => void;
}

const ParamForm: React.FC<Props> = ({ paramState, primBtn, scdBtn, flexWidth, callback }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const newParamValues = {
            ...paramState.paramValues,
            [e.target.name]: e.target.value,
        };

        paramState.setParamValues(newParamValues);
    };

    return (
        <Form action="submit" flexWidth={flexWidth} onSubmit={callback}>
            <section>
                <div>
                    <label htmlFor="parTipo">Tipo</label>
                    <input
                        name="parTipo"
                        type="text"
                        value={paramState.paramValues.parTipo}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parEndereco">Endereco</label>
                    <input
                        name="parEndereco"
                        type="text"
                        value={paramState.paramValues.parEndereco}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parPorta">Porta</label>
                    <input
                        name="parPorta"
                        type="number"
                        value={paramState.paramValues.parPorta}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parUsuario">Usuario</label>
                    <input
                        name="parUsuario"
                        type="text"
                        value={paramState.paramValues.parUsuario}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parSenha">Senha</label>
                    <input
                        name="parSenha"
                        type="password"
                        value={paramState.paramValues.parSenha}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parDatabase">Nome banco</label>
                    <input
                        name="parDatabase"
                        type="text"
                        value={paramState.paramValues.parDatabase}
                        onChange={handleChange}
                    />
                </div>
            </section>
            <section>
                <div>
                    <label htmlFor="parConsultaSetor">Consulta de setores</label>
                    <textarea
                        name="parConsultaSetor"
                        value={paramState.paramValues.parConsultaSetor}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parConsultaUsuario">Consulta de usuários</label>
                    <textarea
                        name="parConsultaUsuario"
                        value={paramState.paramValues.parConsultaUsuario}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parConsultaLotacao">Consulta de lotações</label>
                    <textarea
                        name="parConsultaLotacao"
                        value={paramState.paramValues.parConsultaLotacao}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parConsultaFG">Consulta de funções gratificadas</label>
                    <textarea
                        name="parConsultaFG"
                        value={paramState.paramValues.parConsultaFG}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parConsultaFiscal">Consulta de fiscais</label>
                    <textarea
                        name="parConsultaFiscal"
                        value={paramState.paramValues.parConsultaFiscal}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parConsultaColaborador">Consulta de colaboradores</label>
                    <textarea
                        name="parConsultaColaborador"
                        value={paramState.paramValues.parConsultaColaborador}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="parConsultaAniversariante">Consulta de aniversariantes</label>
                    <textarea
                        name="parConsultaAniversariante"
                        value={paramState.paramValues.parConsultaAniversariante}
                        onChange={handleChange}
                    />
                </div>
            </section>
            <section className="buttons">
                {primBtn}
                {scdBtn}
            </section>
        </Form>
    );
};

export default ParamForm;
