/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { FaFilter } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Container } from '../../components/styleComponents';
import getData from '../../utils/getData';
import Loader from '../../components/Loader';
import { ISetor } from '../../interfaces';
import Area from '../../components/Area';
import { FilterContainer } from './style';
import api from '../../service/api';

const Areas: React.FC = () => {
    const [setores, setSetores] = useState([] as ISetor[]);
    const [setoresShow, setSetoresShow] = useState([] as ISetor[]);
    const [showContent, setShowContent] = useState(false);

    const history = useHistory();

    const filterRef = useRef<HTMLFormElement>();

    const updateSetores = (): void => {
        getData<ISetor[]>('/setores', setSetores, history);
    };

    useEffect(() => {
        const setInitialData = async (): Promise<void> => {
            const token = sessionStorage.getItem('token');
            if (token) {
                api.defaults.headers.Authorization = `Bearer ${token}`;
                api.defaults.headers.chaValor = process.env.REACT_APP_CHAVE_API;
                try {
                    const response = await api.get('/setores');
                    const { data } = response;
                    setSetores(data);
                    setSetoresShow(data);
                } catch (err: any) {
                    console.log(err.response.data.error);
                    if (err.response.data.error) {
                        toast.error(err.response.data.error);
                        history.push('/');
                    }
                }
            } else {
                toast.error('Sem Token');
                history.push('/');
            }
        };

        setInitialData().then(() => setShowContent(true));
    }, []);

    const filtrar = (e: React.FormEvent): void => {
        e.preventDefault();
        const form = filterRef.current;
        const nome = form.nome.value.toLowerCase();
        const id = Number(form.setId.value);
        const area = Number(form.area.value);

        const setoresFilterNome = nome
            ? setores.filter(({ setNome }) => setNome.toLowerCase().includes(nome))
            : setores;

        const setoresFilterId = id
            ? setoresFilterNome.filter(({ setId }) => setId === id)
            : setoresFilterNome;

        const setoresFilterArea = area
            ? setoresFilterId.filter(({ setArea }) => setArea === area)
            : setoresFilterId;

        setSetoresShow(setoresFilterArea);
    };

    if (showContent) {
        return (
            <div>
                <Container>
                    <FilterContainer>
                        <h2>Filtros</h2>
                        <form className="flex-container" ref={filterRef} onSubmit={filtrar}>
                            <div>
                                <label htmlFor="nome">Nome</label>
                                <input type="text" name="nome" />
                            </div>
                            <div>
                                <label htmlFor="setId">Id</label>
                                <input type="number" name="setId" />
                            </div>
                            <div>
                                <label htmlFor="area">Área</label>
                                <input type="number" name="area" />
                            </div>
                            <button type="submit">
                                <FaFilter size={20} />
                            </button>
                        </form>
                    </FilterContainer>
                    <hr />
                    {setoresShow.map((setor) => (
                        <Area key={setor.setId} setor={setor} updateSetores={updateSetores} />
                    ))}
                </Container>
            </div>
        );
    }

    return <Loader />;
};

export default Areas;
