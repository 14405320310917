import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { IParametro } from '../../interfaces';
import api from '../../service/api';
import showError from '../../utils/showError';

const apiParametroCall = async (
    param: IParametro,
    /* eslint-disable */
    apiMethod: (body: IParametro) => Promise<AxiosResponse>,
    responseHandler: (response?: AxiosResponse) => void
    /* eslint-enable */
): Promise<void> => {
    const parametrosUsados = {
        parTipo: param.parTipo,
        parEndereco: param.parEndereco,
        parPorta: param.parPorta,
        parUsuario: param.parUsuario,
        parSenha: param.parSenha,
        parDatabase: param.parDatabase,
        parConsultaSetor: param.parConsultaSetor,
        parConsultaUsuario: param.parConsultaUsuario,
        parConsultaLotacao: param.parConsultaLotacao,
        parConsultaFG: param.parConsultaFG,
        parConsultaFiscal: param.parConsultaFiscal,
        parConsultaColaborador: param.parConsultaColaborador,
        parConsultaAniversariante: param.parConsultaAniversariante
    };
    try {
        const schema = Yup.object().shape({
            parTipo: Yup.string().required('O parTipo é obrigatório'),
            parEndereco: Yup.string().required('O parEndereco é obrigatório'),
            parPorta: Yup.number().required('O parPorta é obrigatório'),
            parUsuario: Yup.string().required('O parUsuario é obrigatório'),
            parSenha: Yup.string().required('O parSenha é obrigatório'),
            parDatabase: Yup.string().required('O parDatabase é obrigatório'),
            parConsultaSetor: Yup.string().required('O parConsultaSetor é obrigatório'),
            parConsultaUsuario: Yup.string().required('O parConsultaUsuario é obrigatório'),
            parConsultaLotacao: Yup.string().required('O parConsultaLotacao é obrigatório'),
            parConsultaFG: Yup.string().required('O parConsultaFG é obrigatório'),
            parConsultaFiscal: Yup.string().required('O parConsultaFiscal é obrigatório'),
            parConsultaAniversariante: Yup.string().required('O parConsultaAniversariante é obrigatório')
        });
        await schema.validate(parametrosUsados, {
            abortEarly: false,
        });

        apiMethod(parametrosUsados)
            .then(responseHandler)
            .catch((erro) => {
                showError(erro);
            });
    } catch (erro) {
        if (erro instanceof Yup.ValidationError) {
            erro.inner.forEach((error) => {
                toast.error(error.message);
            });
        }
    }
};

export const criarParametro = async (
    param: IParametro,
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    state: {
        params: IParametro[];
        setParams: React.Dispatch<React.SetStateAction<IParametro[]>>;
    }
): Promise<void> => {
    const apiMethod = (body: IParametro): Promise<AxiosResponse> => {
        return api.post(`/parametros`, body, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        });
    };

    const responseHandler = (response: AxiosResponse): void => {
        setModalIsOpen(false);
        toast.success('Parâmetro criado com sucesso');
        const newParams = [response.data, ...state.params];
        state.setParams(newParams);
    };

    apiParametroCall(param, apiMethod, responseHandler);
};

export const atualizarParametro = async (param: IParametro): Promise<void> => {
    const apiMethod = (body: IParametro): Promise<AxiosResponse> => {
        return api.put(`/parametros/${param.parId}`, body, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        });
    };

    const responseHandler = (): void => {
        toast.success('Parâmetro atualizado com sucesso');
    };

    apiParametroCall(param, apiMethod, responseHandler);
};

export const excluirParametro = async (
    parId: number,
    state: {
        params: IParametro[];
        setParams: React.Dispatch<React.SetStateAction<IParametro[]>>;
    }
): Promise<void> => {
    api.delete(`/parametros/${parId}`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
    })
        .then(() => {
            toast.success('Parâmetro excluido com sucesso');
            const newParams = state.params.filter((param) => param.parId !== parId);
            state.setParams(newParams);
        })
        .catch((erro) => {
            showError(erro);
        });
};
