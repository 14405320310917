import styled from 'styled-components';

export const Sidebar = styled.div`
    background: rgba(0, 0, 0, 0.6);
    border-right: 1px solid #000;
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
`;

export const NavTop = styled.div`
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    cursor: default;
    img {
        width: 150px;
        height: auto;
        margin: 30px auto 0 auto;
        opacity: 0.8;
    }
    h2,
    button {
        background: none;
        margin: 0;
        padding: 0;
        color: #fff;
    }

    button {
        transition: 0.1s;
        cursor: pointer;

        &:hover {
            color: rgba(255, 255, 255, 0.3);
        }

        &:active,
        &:focus {
            color: rgba(0, 0, 0, 0.7);
        }
        * {
            margin: auto 4px;
            font-size: 20px;
        }
    }
`;

export const NavItems = styled.ul`
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    margin-top: -40px;
    li {
        transition: 0.1s;
        cursor: pointer;
        font-size: 25px;
        margin: 25px auto;

        &:hover {
            color: rgba(255, 255, 255, 0.3);
        }

        &:active,
        &:focus {
            color: rgba(0, 0, 0, 0.7);
        }

        * {
            vertical-align: middle;
            display: inline-block;
            margin: auto 4px;
        }
    }
`;

export const ChildrenContainer = styled.div`
    margin-left: 250px;
`;
