import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import showError from '../../utils/showError';
import api from '../../service/api';
import { IChave } from '../../interfaces';

const apiChaveCall = async (
    chave: IChave,
    /* eslint-disable */
    apiMethod: (body: IChave) => Promise<AxiosResponse>,
    responseHandler: (response?: AxiosResponse) => void
    /* eslint-enable */
): Promise<void> => {
    const chaveUsada = {
        chaValor: chave.chaValor,
        chaSistema: chave.chaSistema,
    };
    try {
        const schema = Yup.object().shape({
            chaValor: Yup.string().required('O valor da chave é obrigatório'),
            chaSistema: Yup.string().required('O sistema da chave é obrigatório'),
        });
        await schema.validate(chaveUsada, {
            abortEarly: false,
        });

        apiMethod(chaveUsada)
            .then(responseHandler)
            .catch((erro) => {
                showError(erro);
            });
    } catch (erro) {
        if (erro instanceof Yup.ValidationError) {
            erro.inner.forEach((error) => {
                toast.error(error.message);
            });
        }
    }
};

export const criarChave = (
    chave: IChave,
    state: {
        chaves: IChave[];
        setChaves: React.Dispatch<React.SetStateAction<IChave[]>>;
    }
): void => {
    const apiMethod = (body: IChave): Promise<AxiosResponse> => {
        return api.post(`/chaves`, body, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        });
    };

    const responseHandler = (response: AxiosResponse): void => {
        // setModalIsOpen(false);
        toast.success('Chave criada com sucesso');
        const newChaves = [response.data, ...state.chaves];
        state.setChaves(newChaves);
    };

    apiChaveCall(chave, apiMethod, responseHandler);
};

export const atualizarChave = async (chave: IChave): Promise<void> => {
    const apiMethod = (body: IChave): Promise<AxiosResponse> => {
        return api.put(`/chaves/${chave.chaId}`, body, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        });
    };

    const responseHandler = (): void => {
        toast.success('Chave atualizada com sucesso');
    };

    apiChaveCall(chave, apiMethod, responseHandler);
};

export const excluirChave = async (
    chaveId: number,
    state: {
        chaves: IChave[];
        setChaves: React.Dispatch<React.SetStateAction<IChave[]>>;
    }
): Promise<void> => {
    api.delete(`/chaves/${chaveId}`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
    })
        .then(() => {
            toast.success('Chave excluída com sucesso');
            const newChaves = state.chaves.filter((chave) => chave.chaId !== chaveId);
            state.setChaves(newChaves);
        })
        .catch((erro) => {
            showError(erro);
        });
};
