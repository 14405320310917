import React, { useState } from 'react';
// import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaSave, FaTrash } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import { IChave } from '../../interfaces';
import { atualizarChave, excluirChave } from './handlers';
import ChaveForm from '../ChaveForm/ChaveForm';

interface Props {
    chave: IChave;
    state: {
        chaves: IChave[];
        setChaves: React.Dispatch<React.SetStateAction<IChave[]>>;
    };
}

const Chave: React.FC<Props> = ({ chave, state }) => {
    const [chaveValues, setChaveValues] = useState({
        ...chave,
    });

    const primBtn = (
        <button type="submit" title="Atualizar">
            <FaSave size={25} />
        </button>
    );

    const scdBtn = (
        <button
            type="button"
            onClick={() => {
                confirmAlert({
                    title: 'Confirmar Exclusão',
                    message: 'Tem certeza que deseja fazer isso?',
                    buttons: [
                        {
                            label: 'Sim',
                            onClick: () => excluirChave(chave.chaId, state),
                        },
                        {
                            label: 'Não',
                            onClick: () => undefined,
                        },
                    ],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }}>
            <FaTrash size={25} />
        </button>
    );

    return (
        <>
            <ChaveForm
                chaveState={{ chaveValues, setChaveValues }}
                primBtn={primBtn}
                scdBtn={scdBtn}
                flex={false}
                callback={(e) => {
                    e.preventDefault();
                    atualizarChave(chaveValues);
                }}
                hideDate
            />
            <hr />
        </>
    );
};

export default Chave;
