import styled from 'styled-components';

export const AreaContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    margin: 50px auto;
    justify-content: center;

    label {
        font-size: 25px;
        font-weight: 500;
    }

    .info {
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            font-size: 20px;
            cursor: default;
        }
        width: 25%;
    }

    .setArea {
        * {
            width: 80%;
            text-align: center;
            display: block;
        }

        input {
            background: rgba(89, 74, 102, 0.7);
            padding: 5px;
            border: none;
            border-radius: 5px;
            box-shadow: 0px 3px 7px rgba(14, 31, 53, 0.12);
            font-size: 20px;
            color: rgba(0, 0, 0, 0.85);

            &:focus,
            &:active {
                outline: 1.5px solid #000;
            }
        }
    }

    .buttons {
        button:nth-child(2) {
            background: #e02020;
        }
    }
`;
