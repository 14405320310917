import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Container, NewBtn } from '../../components/styleComponents';
import Vazio from '../../components/Vazio/Vazio';
import ModalChave from '../../components/Chave/ModalChave';
import Chave from '../../components/Chave/Chave';
import getData from '../../utils/getData';
import { IChave } from '../../interfaces';
import Loader from '../../components/Loader';

const Chaves: React.FC = () => {
    const [chaves, setChaves] = useState([]);
    const [showContent, setShowContent] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        getData<IChave[]>('/chaves', setChaves, history).then(() => setShowContent(true));
    }, [history]);

    if (showContent) {
        return (
            <div>
                <NewBtn type="button" onClick={() => setModalIsOpen(true)}>
                    Nova Chave
                </NewBtn>
                {chaves.length === 0 && <Vazio text="chaves" />}
                <Container>
                    {chaves.map((chave) => (
                        <Chave key={chave.chaId} chave={chave} state={{ chaves, setChaves }} />
                    ))}
                </Container>
                <ModalChave
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    state={{ chaves, setChaves }}
                />
            </div>
        );
    }
    return <Loader />;
};

export default Chaves;
