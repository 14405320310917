import styled from 'styled-components';

const Group = styled.div`
    padding: 15px 15px 0 15px;
    max-height: 700px;
    overflow-y: scroll;

    h1 {
        font-size: 50px;
    }

    p {
        margin: 15px auto;
    }

    div {
        display: flex;
    }
    .container {
    }

    .left {
        float: left;
    }

    .right {
        float: right;
    }

    table {
        margin: auto;
        border: 1px solid #000;
        border-radius: 5px;

        th {
            color: #fff;
            font-size: 25px;
            background: rgba(0, 0, 0, 0.6);
        }

        tr {
            &:hover {
                background: rgba(255, 255, 255, 0.3);
            }
        }

        tr:nth-of-type(even) {
            background: rgba(0, 0, 0, 0.23);

            &:hover {
                background: rgba(0, 0, 0, 0.16);
            }
        }

        td {
            padding: 5px;
            font-size: 20px;
        }
    }
`;

export default Group;
