import React, { ReactElement } from 'react';
import formatDate from '../../utils/formatDate';
import { IChave } from '../../interfaces';
import handleInputs from '../../utils/handleInputs';
import { Form } from './style';

interface Props {
    chaveState: {
        chaveValues: IChave;
        setChaveValues: React.Dispatch<React.SetStateAction<IChave>>;
    };
    primBtn: ReactElement;
    scdBtn: ReactElement;
    flex: boolean;
    // eslint-disable-next-line
    callback: (...args: any[]) => void;
    hideDate: boolean;
}

const ChaveForm: React.FC<Props> = ({ chaveState, primBtn, scdBtn, flex, callback, hideDate }) => {
    return (
        <Form onSubmit={callback} flex={flex}>
            <div>
                <label htmlFor="chaValor">Valor</label>
                <input
                    type="text"
                    name="chaValor"
                    value={chaveState.chaveValues.chaValor}
                    onChange={(e) =>
                        handleInputs<IChave>(e, chaveState.chaveValues, chaveState.setChaveValues)
                    }
                />
            </div>
            <div>
                <label htmlFor="chaSistema">Sistema</label>
                <input
                    type="text"
                    name="chaSistema"
                    value={chaveState.chaveValues.chaSistema}
                    onChange={(e) =>
                        handleInputs<IChave>(e, chaveState.chaveValues, chaveState.setChaveValues)
                    }
                />
            </div>
            {hideDate && (
                <section className="date">
                    <div>
                        <label>Criado</label>
                        <p>{formatDate(chaveState.chaveValues.chaCriado)}</p>
                    </div>
                    <div>
                        <label>Atualizado</label>
                        <p>{formatDate(chaveState.chaveValues.chaAtualizado)}</p>
                    </div>
                </section>
            )}
            <section className="buttons">
                {primBtn}
                {scdBtn}
            </section>
        </Form>
    );
};

export default ChaveForm;
