import styled from 'styled-components';

interface Props {
    flex: boolean;
}

export const Form = styled.form<Props>`
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    margin: 50px auto;
    justify-content: space-evenly;

    ${(props) =>
        props.flex &&
        `
        min-height: 400px;
        flex-direction: column;
        align-items: center;
        .date {
            display: none;
        }
        `}
    div {
        * {
            display: block;
        }

        label {
            font-size: 25px;
            font-weight: 500;
            text-align: center;
        }

        input {
            text-align: center;
            background: rgba(89, 74, 102, 0.7);
            padding: 5px;
            border: none;
            border-radius: 5px;
            box-shadow: 0px 3px 7px rgba(14, 31, 53, 0.12);
            font-size: 20px;
            color: rgba(0, 0, 0, 0.85);

            &:focus,
            &:active {
                outline: 1.5px solid #000;
            }
        }

        [name='chaValor'] {
            width: 400px;
        }

        [name='chaSistema'] {
            width: 180px;
        }
    }

    .date {
        div {
            display: inline-block;
            margin: auto 10px;
            p {
                padding: 0;
            }
        }
    }

    .buttons {
        text-align: center;
        button {
            width: 50px;
            ${(props) =>
                props.flex &&
                `
                width: 100px;
                `}
            height: 50px;
            max-width: 150px;
            max-height: 110px;
            font-size: 20px;
            &:nth-child(2) {
                background: #e02020;
            }
        }
    }
`;
