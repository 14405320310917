import React, { useState } from 'react';
import Modal from 'react-modal';
import { IChave } from '../../interfaces';
import ChaveForm from '../ChaveForm/ChaveForm';
import { criarChave } from './handlers';

interface Props {
    modalIsOpen: boolean;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    state: {
        chaves: IChave[];
        setChaves: React.Dispatch<React.SetStateAction<IChave[]>>;
    };
}

const ModalChave: React.FC<Props> = ({ modalIsOpen, setModalIsOpen, state }) => {
    const [chaveValues, setChaveValues] = useState({
        chaValor: '',
        chaSistema: '',
    });

    const primBtn = <button type="submit">Criar</button>;

    const scdBtn = (
        <button type="button" onClick={() => setModalIsOpen(false)}>
            Fechar
        </button>
    );

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(216, 222, 233, 0.75)',
                    overflow: 'scroll',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #000',
                    backgroundColor: 'rgba(237, 237, 237, 0.95)',
                    overflow: 'hidden',
                    paddingTop: '0',
                },
            }}
            ariaHideApp={false}>
            <ChaveForm
                chaveState={{ chaveValues, setChaveValues }}
                primBtn={primBtn}
                scdBtn={scdBtn}
                flex
                callback={(e) => {
                    e.preventDefault();
                    criarChave(chaveValues, state);
                }}
                hideDate={false}
            />
        </Modal>
    );
};

export default ModalChave;
