import React, { useRef } from 'react';
import { FaSave, FaTrash } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { ISetor } from '../../interfaces';
import { AreaContainer } from './style';
import api from '../../service/api';

interface IProps {
    setor: ISetor;
    updateSetores: () => void;
}

const Area: React.FC<IProps> = ({ setor, updateSetores }) => {
    const inputRef = useRef<HTMLInputElement>();

    const setArea = async (): Promise<void> => {
        api.defaults.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
        api.defaults.headers.chaValor = process.env.REACT_APP_CHAVE_API;
        const area = inputRef.current.value;
        try {
            const response = await api.put(`/setores/area/${setor.setId}`, {
                area: area.length === 0 ? null : Number(area),
            });
            if (response.status === 200) {
                toast.success('Área atualizada com sucesso');
                updateSetores();
            }
        } catch (err: any) {
            console.log(err.response.data.error);
            if (err.response.data.error) {
                toast.error(err.response.data.error);
            }
        }
    };

    const limpar = (): void => {
        inputRef.current.value = null;
        setArea();
    };

    const SaveBtn = (): React.ReactElement => (
        <button type="button" title="Atualizar" onClick={setArea}>
            <FaSave size={25} />
        </button>
    );

    const TrashBtn = (): React.ReactElement => (
        <button
            type="button"
            onClick={() => {
                confirmAlert({
                    title: 'Confirmar Limpeza',
                    message: 'Tem certeza que deseja desvincular o setor da área?',
                    buttons: [
                        {
                            label: 'Sim',
                            onClick: () => limpar(),
                        },
                        {
                            label: 'Não',
                            onClick: () => undefined,
                        },
                    ],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }}>
            <FaTrash size={25} />
        </button>
    );

    return (
        <>
            <AreaContainer>
                <div className="info">
                    <label htmlFor="setNome">Nome</label>
                    <p title={setor.setNome}>{setor.setNome}</p>
                </div>
                <div className="info">
                    <label htmlFor="setId">Id</label>
                    <p>{setor.setId}</p>
                </div>
                <div className="setArea">
                    <label htmlFor="setArea">Área</label>
                    <input ref={inputRef} type="text" name="setArea" defaultValue={setor.setArea} />
                </div>
                <div className="buttons">
                    <SaveBtn />
                    <TrashBtn />
                </div>
            </AreaContainer>
            <hr />
        </>
    );
};

export default React.memo(Area);
