import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;

    h1 {
        color: #fff;
        font-size: 35px;
        margin-bottom: 100px;
        text-align: center;
    }
`;

const rotate = keyframes`
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: -65px;
        left: -65px;
        width: 200px;
        height: 200px;
        opacity: 0;
    }
`;

const LoaderCircle = styled.div`
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;

    div {
        position: absolute;
        border: 4px solid #c1c1c1;
        opacity: 1;
        border-radius: 50%;
        animation: ${rotate} 1.3s cubic-bezier(0, 0.2, 0.8, 1) infinite;

        &:nth-child(2) {
            animation-delay: -0.5s;
        }
    }
`;

const Loader: React.FC = () => {
    return (
        <LoaderContainer>
            <h1>Procurando dados no banco</h1>
            <LoaderCircle>
                <div />
                <div />
            </LoaderCircle>
        </LoaderContainer>
    );
};

export default Loader;
