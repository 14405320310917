import React, { useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { CenterContainer, Form } from './style';
import api from '../../service/api';
import showError from '../../utils/showError';

const Login: React.FC = () => {
    const [login, setLogin] = useState(sessionStorage.getItem('login') || '');
    const [senha, setSenha] = useState('');
    const history = useHistory();

    function limparSessao(): void {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('login');
    }

    async function logar(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        limparSessao();

        try {
            const schema = Yup.object().shape({
                login: Yup.string().required('O login é obrigatório'),
                senha: Yup.string().required('A senha é obrigatória'),
            });
            await schema.validate(
                { login, senha },
                {
                    abortEarly: false,
                }
            );

            api.post('/login', {
                login,
                senha,
            })
                .then((response) => {
                    sessionStorage.setItem('token', response.data.token);
                    sessionStorage.setItem('login', response.data.usuLogin);

                    history.push('/parametros');
                })
                .catch((erro) => {
                    showError(erro);
                });
        } catch (erro) {
            if (erro instanceof Yup.ValidationError) {
                erro.inner.forEach((error) => {
                    toast.error(error.message);
                });
            }
        }
    }

    return (
        <>
            <CenterContainer>
                <h1>Painel de controle CMC-api</h1>
                <Form action="login" onSubmit={logar}>
                    <div>
                        <label htmlFor="login">Login</label>
                        <input
                            type="text"
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="senha">Senha</label>
                        <input
                            type="password"
                            value={senha}
                            onChange={(e) => setSenha(e.target.value)}
                        />
                    </div>
                    <button type="submit">Entrar</button>
                </Form>
            </CenterContainer>
        </>
    );
};

export default Login;
