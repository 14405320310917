import React from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalStyle from './styles/global';
import Login from './pages/Login';
import Layout from './components/Layout/Layout';
import Parametros from './pages/Parametros';
import Chaves from './pages/Chaves';
import Relatorios from './pages/Relatorios';
import NotFound from './pages/NotFound';
import Areas from './pages/Areas';

interface RouteWrapperProps extends RouteProps {
    // eslint-disable-next-line
    component: any;
    // eslint-disable-next-line
    children?: any;
}

const RouteWrapper = (propts: RouteWrapperProps) => {
    const { component: Component, ...rest } = propts;
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout {...props}>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};

const App: React.FC = () => {
    return (
        <>
            <GlobalStyle />
            <ToastContainer autoClose={4000} style={{ width: '400px' }} position="top-right" />

            <Switch>
                <Route exact path="/" component={Login} />
                <RouteWrapper exact path="/parametros" component={Parametros} />
                <RouteWrapper exact path="/chaves" component={Chaves} />
                <RouteWrapper exact path="/relatorios" component={Relatorios} />
                <RouteWrapper exact path="/areas" component={Areas} />
                <Route path="*" component={NotFound} />
            </Switch>
        </>
    );
};

export default App;
