import React, { useState } from 'react';
import Modal from 'react-modal';
import { IParametro } from '../../interfaces';
import ParamForm from '../ParamForm/ParamForm';
import { criarParametro } from './handlers';

interface Props {
    modalIsOpen: boolean;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    state: {
        params: IParametro[];
        setParams: React.Dispatch<React.SetStateAction<IParametro[]>>;
    };
}

const ModalParametro: React.FC<Props> = ({ modalIsOpen, setModalIsOpen, state }) => {
    const [paramValues, setParamValues] = useState({
        parTipo: '',
        parEndereco: '',
        parPorta: 0,
        parUsuario: '',
        parSenha: '',
        parDatabase: '',
        parConsultaSetor: '',
        parConsultaUsuario: '',
        parConsultaLotacao: '',
        parConsultaFG: '',
        parConsultaFiscal: '',
        parConsultaColaborador: '',
        parConsultaAniversariante: '',
    });

    const primBtn = <button type="submit">Criar</button>;

    const scdBtn = (
        <button type="button" onClick={() => setModalIsOpen(false)}>
            Fechar
        </button>
    );

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(216, 222, 233, 0.75)',
                    zIndex: 2,
                },
                content: {
                    top: '50%',
                    left: '50%',
                    bottom: '-40%',
                    marginRight: '-30%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #000',
                    backgroundColor: 'rgba(216, 222, 233, 0.95)',
                },
            }}
            ariaHideApp={false}>
            <ParamForm
                paramState={{ paramValues, setParamValues }}
                primBtn={primBtn}
                scdBtn={scdBtn}
                flexWidth="100%"
                callback={(e) => {
                    e.preventDefault();
                    criarParametro(paramValues, setModalIsOpen, state);
                }}
            />
        </Modal>
    );
};

export default ModalParametro;
