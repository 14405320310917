import React from 'react';
import styled from 'styled-components';

const CenterContent = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
`;

const Vazio: React.FC<{ text: string }> = ({ text }) => {
    return (
        <CenterContent>
            <h1>Ainda não existem {text} no banco!</h1>
        </CenterContent>
    );
};

export default Vazio;
