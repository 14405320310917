import styled from 'styled-components';

export const FilterContainer = styled.section`
    background-color: #c2d6d6;
    padding: 1rem;

    .flex-container {
        display: flex;
        justify-content: space-evenly;
        margin-top: 1.5rem;
        flex-wrap: wrap;
    }

    div > * {
        display: block;
        text-align: center;
    }
`;
